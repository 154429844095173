/** Shape of assets loaded with the next.js loader */
export type NextLoadedAsset = {
  src: string;
  height?: number;
  width?: number;
  blurDataURL?: string;
};

/**
 * Helper to get the url of an asset
 *
 * Workaround to get an assets `src` URL that works with both the next.js and
 * Storybook/Jest asset loader return format.
 *
 * This is just a temporary workaround till Storybook has better support for Next.js
 * See: https://storybook.js.org/blog/integrate-nextjs-and-storybook-automatically/
 *
 * @returns the normalized url of `asset`
 */
export const getSrc = (asset: NextLoadedAsset | string): string => (typeof asset === 'string' ? asset : asset.src);
