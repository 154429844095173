import React, { ReactElement } from 'react';
import { PageLayoutProps } from './types';
import Layout from '~components/pageLayout/SurveyMonkey';

const PageLayout = ({ children, layout, translationData, staticData }: PageLayoutProps): ReactElement => {
  if (!layout) {
    return children;
  }
  // Hardcode SurveyMonkey for now as there is no other Variant and it breaks SSR
  // const variant = layout.variant ?? layout;
  // const Layout = dynamic(import(`~components/pageLayout/${variant}`), { ssr: true }) as PageLayoutComponent;

  return (
    <Layout layout={layout} staticData={staticData} translationData={translationData}>
      {children}
    </Layout>
  );
};

export default PageLayout;
