import React from 'react';
import { Button as WrenchButton, ButtonProps as WrenchButtonProps } from '@wds/button';

type HTMLWrapperAttributes<T> = React.AllHTMLAttributes<T> & {
  // Until TS 4.4.x there is no way to specifically define a key-pattern
  // This is to allow data-* attributes to be passed as an object key.
  [dataAttributes: string]: unknown;
};

export type ButtonProps = WrenchButtonProps & {
  /** element tag for the container (defaults to 'div') */
  element?: 'div' | 'span';
  /** Classes to be added to the `element` */
  className?: string;
  /** additional props specific to the `element` (trap-door); excludes `className` */
  componentProps?: Omit<HTMLWrapperAttributes<HTMLDivElement | HTMLSpanElement>, 'className'>;
};

/**
 * @description Generic Stylable Button.
 * This component can be used in place of the `@wds/button` component to
 * modify the styles, until the `@wds/button` version supports className prop
 * passthrough.
 */
export default function Button({
  element: Element = 'div',
  className,
  componentProps,
  ...buttonProps
}: ButtonProps): React.ReactElement {
  return (
    <Element {...componentProps} className={className}>
      <WrenchButton {...buttonProps} />
    </Element>
  );
}
