import React, { ReactElement } from 'react';
import { StaticProvider } from '@sm/webassets/StaticContext';
import { HelmetProvider } from '@sm/webassets/Helmet';
import SurveyBasePage from '~app/components/shared/BasePage/SurveyBasePage';
import { PageLayoutProps } from './types';

const SurveyMonkey = ({
  children,
  layout: {
    options: { translationData: _translationData, ...layoutOptions },
  },
  translationData,
  staticData,
}: PageLayoutProps): ReactElement => {
  return (
    <HelmetProvider>
      <StaticProvider value={staticData}>
        <SurveyBasePage translationData={translationData} {...layoutOptions}>
          {children}
        </SurveyBasePage>
      </StaticProvider>
    </HelmetProvider>
  );
};

export default SurveyMonkey;
