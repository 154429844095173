enum ANALYTICS_EVENTS {
  USER_INTERACTION = 'spage user interaction',
  SURVEY_LOADED = 'spage loaded survey page',
  SURVEY_COMPLETE = 'spage completed survey',
  SURVEY_PARTIAL = 'spage generated partial response',
}

enum ANALYTICS_INTERACTION_TYPE {
  CLICK = 'click',
  ENABLE = 'enable',
  DISABLE = 'disable',
}

enum ANALYTICS_WEBTYPE_TYPE {
  RESPWEB = 'respweb',
}

enum ANALYTICS_LOCATION {
  SURVEY_FOOTER = 'footer',
  CONFIRMATION_EMAIL_CHECKBOX = 'confirmation email checkbox',
  PAYMENT_CHECKOUT_CLICK = 'payment checkout button',
}

enum FOOTER_ANALYTICS_LINK_TEXT {
  POWERED_BY_CLICK = 'footer-powered-by',
  CREATE_SURVEY_CLICK = 'footer-create-survey',
}

enum ENDPAGE_APP_TYPE {
  RESPWEB = 'respweb',
}

enum ENDPAGE_ERROR_TYPE {
  FORM_VALIDATION = 'form validation',
}

enum ENDPAGE_EVENTS {
  USER_INTERACTION = 'endpage user interaction',
  ERRORED = 'endpage errored',
}

enum ENDPAGE_INTERACTION_TYPE {
  CLICK = 'click',
  FOCUSED = 'focused',
  ENTERED_TEXT = 'entered text',
  SUBMITTED = 'submitted',
}

enum ENDPAGE_INTERACTION_LOCATION {
  SIGNUP_FORM = 'signup form',
  EMAIL_INPUT = 'email input',
  PASSWORD_INPUT = 'password input',
}

export {
  ANALYTICS_EVENTS,
  ANALYTICS_LOCATION,
  ANALYTICS_INTERACTION_TYPE,
  ENDPAGE_APP_TYPE,
  ENDPAGE_ERROR_TYPE,
  ENDPAGE_EVENTS,
  ENDPAGE_INTERACTION_LOCATION,
  ENDPAGE_INTERACTION_TYPE,
  FOOTER_ANALYTICS_LINK_TEXT,
  ANALYTICS_WEBTYPE_TYPE,
};
